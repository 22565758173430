import firebase from './firebase';

const Auth = firebase.auth();

Auth.useDeviceLanguage();
Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const getUserLocal = () => {
    let token = localStorage.getItem('token');
    if(token){
        try{
            let user = JSON.parse(atob(token.split('.')[1]));
            return user;
        }catch{}
        return null;
    }
    return false;    
}
const isAuthenticatedLocal = () => {
    let user = getUserLocal();
    if(user){        
        return new Date(user.exp*1000) > new Date(Date.now());
    }
    return false;
}

export default Auth;
export {isAuthenticatedLocal, getUserLocal};
