import firebase from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/messaging';
/*
firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});
*/
firebase.initializeApp({
  apiKey: "AIzaSyAkEXMOp3SGUrr5CK22ik66Lya_tfQgw6c",
  authDomain: 'painel.concreteja.com.br',
  /*databaseURL: "https://concreteja-hom.firebaseio.com",*/
  databaseURL: "https://concreteja-cb4d6.firebaseio.com",
  projectId: "concreteja-cb4d6",
  storageBucket: "concreteja-cb4d6.appspot.com",
  messagingSenderId: "1038530269830",
  appId: "1:1038530269830:web:fd42cacbf0d79561c77707"
});
export default firebase;
