import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import AppThemeProvider from './theme/AppThemeProvider';
import { PopMsg } from './components/PopMsg'

const Root: React.FC = () => {

  return (
    <BrowserRouter>      
      <AppThemeProvider>
        <CssBaseline />
        <Routes />
        <PopMsg />
      </AppThemeProvider>      
    </BrowserRouter>
  );
};

export default Root;
