import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import firebase from './services/firebase';
import {isAuthenticatedLocal} from './services/Auth';
import { AlertPop } from './components/PopMsg'

ReactDOM.render(<Root />, document.getElementById('root'));

const msg = firebase.messaging();

const configMsg = async () => {

    if(isAuthenticatedLocal()){        
        const permission = await Notification.requestPermission();  
        if(permission !== 'granted') return;
        const currentTokenMsg = await msg.getToken();
        msg.onMessage((payload) => {
            console.log('interno-Message received. ', payload);
        });        
        let item:any = {};
        item[currentTokenMsg] = currentTokenMsg;
        console.log('currentTokenMsg', currentTokenMsg);
        await firebase.database().ref(`usuarioPushAdmin/${currentTokenMsg}`).set(item);
        firebase.database().ref(`pedidoPush`).orderByChild('enviado').equalTo(false).on('child_added',async (snap, key)=>{
            let obj = snap.val();            
            obj.enviado = true;
            obj.enviadoPainel = true;
            setTimeout(async () => {
                await firebase.database().ref(`pedidoPush/${obj.id}`).set(obj)    
            }, 60000);            
            AlertPop.info(`Novo pedido recebido para: '${obj.nomeEmpresa}'.`,()=>{ document.location.href = `/pedido/${obj.usuarioUid}|${obj.pedidoConcretoId}`; }, 'Conferir', 12000);
        });
    }
    else
    {
        console.log('getUserLocal', 'sem usuário local');
    }   
};

configMsg();

serviceWorker.register();