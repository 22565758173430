import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';

const theme = createMuiTheme({
  palette: {},
});

const AppThemeProvider: React.FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
