import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import PubSub from 'pubsub-js';
import Button from '@material-ui/core/Button';

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const durationMsg = 6000;
const initState = {open:false, class:'info', msg: '', duration: durationMsg, action: function(){}, actionTitle: ''};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function PopMsg() {
  const classes = useStyles();
  const [statePop, setStatePop] = useState(initState);

  useEffect(()=>{    
    PubSub.subscribe('PopMsg.WARNING', (e:any, data:any)=>{
      setStatePop({open: true, class: 'warning', msg: data.msg, duration: data.duration || durationMsg, action: data.action, actionTitle: data.actionTitle });
    });

    PubSub.subscribe('PopMsg.INFO', (e:any, data:any)=>{
      setStatePop({open: true, class: 'info', msg: data.msg, duration: data.duration || durationMsg, action: data.action, actionTitle: data.actionTitle });      
    });
    
    PubSub.subscribe('PopMsg.SUCCESS', (e:any, data:any)=>{      
      setStatePop({open: true, class: 'success', msg: data.msg, duration: data.duration || durationMsg, action: data.action, actionTitle: data.actionTitle });      
    });
    
    PubSub.subscribe('PopMsg.ERROR', (e:any, data:any)=>{      
      setStatePop({open: true, class: 'error', msg: data.msg, duration: data.duration || durationMsg, action: data.action, actionTitle: data.actionTitle });      
    });

    return ()=>{
      PubSub.unsubscribe('PopMsg.WARNING');
      PubSub.unsubscribe('PopMsg.INFO');
      PubSub.unsubscribe('PopMsg.SUCCESS');
      PubSub.unsubscribe('PopMsg.ERROR');
    }    
  }, []);

  const handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }

    setStatePop(initState);
  };

  return (
    <div className={classes.root} hidden={!statePop.open}>
      <Snackbar open={statePop.open} autoHideDuration={statePop.duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={statePop.class}>
          {statePop.msg}          
          {statePop.action && statePop.actionTitle && <Button style={{marginLeft: 25}} variant="contained" color="primary" size="small" onClick={statePop.action}>{statePop.actionTitle}</Button>}
        </Alert>
      </Snackbar>
    </div>
  );
}

function AlertPop () {
  return {
      error:(msg:any, action:any=null, actionTitle:string='', duration:any=null) => {
        return new Promise((resolve, reject)=>{
          let loopCheck = setInterval(() => {            
            if(PubSub.countSubscriptions('PopMsg.ERROR')){
              PubSub.publishSync('PopMsg.ERROR', {msg, duration, action, actionTitle});
              clearInterval(loopCheck);
              resolve();
            }
          });
        });
      },
      warning:(msg:any, action:any=null, actionTitle:string='', duration:any=null) => {          
        return new Promise((resolve, reject)=>{
          let loopCheck = setInterval(() => {            
            if(PubSub.countSubscriptions('PopMsg.WARNING')){
              PubSub.publishSync('PopMsg.WARNING', {msg, duration, action, actionTitle});
              clearInterval(loopCheck);
              resolve();
            }
          });
        });          
      },    
      info: (msg:any, action:any=null, actionTitle:string='', duration:any=null) => {
        return new Promise((resolve, reject)=>{
          let loopCheck = setInterval(() => {            
            if(PubSub.countSubscriptions('PopMsg.INFO')){
              PubSub.publishSync('PopMsg.INFO', {msg, duration, action, actionTitle});
              clearInterval(loopCheck);
              resolve();
            }
          });
        });
      },    
      success: async (msg:any, action:any=null, actionTitle:string='', duration:any=null) => {
        return new Promise((resolve, reject)=>{
          let loopCheck = setInterval(() => {            
            if(PubSub.countSubscriptions('PopMsg.SUCCESS')){
              PubSub.publishSync('PopMsg.SUCCESS', {msg, duration, action, actionTitle});
              clearInterval(loopCheck);
              resolve();
            }
          });
        });
      }
  }
}

const alert = AlertPop();

export {
  PopMsg,
  alert as AlertPop
}